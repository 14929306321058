import axios from 'axios';

let baseURL = 'http://localhost:5000';
if(process.env.NODE_ENV == 'development'){
  baseURL = 'https://test2.fundacjaskarbowosci.pl'
}else if(process.env.NODE_ENV == 'production'){
  baseURL = 'https://mps.fundacjaskarbowosci.pl'
}

const instance = axios.create({
  baseURL,
  validateStatus: function (status) {
    return status >= 200 && status <= 503;
  },
});

instance.defaults.withCredentials = true;
instance.defaults.credentials = 'include';

export default instance;