/**
 * Instantiate Accordion
 * @param  {Node}       wrapper Dom class or dom node
 * @param  {Object}     options Object holding optional parameters
 * @return {undefined}
 */
export default class Accordion{
  constructor( wrapper, options = {} ){
    /** Check if wrapper is already dom element */
    if(wrapper instanceof Element || wrapper instanceof HTMLDocument){
      this.wrapperDOM = wrapper;
    }else{
      this.wrapperDOM = document.querySelector(wrapper);
    }
    if(!this.wrapperDOM) throw new Error('You must specify accordion wrapper!');

    this.items = options.items || '.js-accordion__header';
    this.itemsDOM = this.wrapperDOM.querySelectorAll(this.items);
    this.collapse = this.wrapperDOM.getAttribute('data-collapse') || true;

    this.boundActivateEvent;

    this._bindEvents();
  }

  _bindEvents() {
    this.boundActivateEvent = (event) => this._activate(event);
    this.itemsDOM.forEach(item => item.addEventListener('click', this.boundActivateEvent))
  }

  /** Call this method whenever accordion item has been added */
  /** Removes existing click event and adds new one */
  recache() {
    this.itemsDOM = this.wrapperDOM.querySelectorAll(this.items);
    this.itemsDOM.forEach(item => {
      item.removeEventListener('click', this.boundActivateEvent)
      item.addEventListener('click', this.boundActivateEvent)
    })
  }

  _activate( event ) {
    const item = event.currentTarget;

    if (this.collapse) {
      console.log('co jest grane');
      for (let i = 0; i < this.itemsDOM.length; i++) {
        if (i !== [...this.itemsDOM].indexOf(item)) {
          this.itemsDOM[i].parentNode.classList.remove('is-active');
        }
      }
    }

    item.parentNode.classList.toggle('is-active');
  }
}