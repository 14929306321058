import Choices from "choices.js"
import axios from "../vendors/axios"
import updateURLParameter from "../methods/updateUrlParameter"
import pushUrl from "../methods/pushUrl"

try {
  const choices = new Choices(".js-choice", {
    itemSelectText: "",
    searchFloor: 3,
    searchResultLimit: 30,
    searchFields: ["label"],
    shouldSort: false,
    noResultsText: "Brak wyników"
  })
} catch (err) {}

try {
  const choicesSort = new Choices(".js-choice-sort")

  choicesSort.passedElement.element.addEventListener("change", function(e) {
    let newURL = updateURLParameter(window.location.href, "sort", e.detail.value)
    newURL = updateURLParameter(newURL, "page", 1)
    pushUrl(newURL)
  })
} catch (err) {}

try {
  const choicesSort = new Choices(".js-choice-filter")

  choicesSort.passedElement.element.addEventListener("change", function(e) {
    let newURL = updateURLParameter(window.location.href, "filter", e.detail.value)
    newURL = updateURLParameter(newURL, "page", 1)
    pushUrl(newURL)
  })
} catch (err) {}

try {
  const choicesSort = new Choices(".js-choice-unit")

  choicesSort.passedElement.element.addEventListener("change", function(e) {
    let newURL = updateURLParameter(window.location.href, "unit", e.detail.value)
    newURL = updateURLParameter(newURL, "page", 1)
    pushUrl(newURL)
  })
} catch (err) {}

try {
  const choicesSort = new Choices(".js-choice-scores")
  const infoDOM = document.querySelector('.js-category-info')

  choicesSort.passedElement.element.addEventListener("change", function(e) {
    const fields = e.detail.value.split("-")
    let newURL = updateURLParameter(window.location.href, "sex", fields[0])
    newURL = updateURLParameter(newURL, "category", fields[1])
    newURL = updateURLParameter(newURL, "page", 1)
    if(fields[0] && fields[1]) pushUrl(newURL)

    /** Set new choice info */
    let sex = "";
    if(fields[0] === "K")
      sex = "Kobiety"
    else if(fields[0] === "M")
      sex = "Mężczyźni"
    
    let category = "";
    if(fields[1] === "A")
      category = "Kat. A (od 18 do 35)"
    else if(fields[1] === "B")
      category = "Kat. B (od 36 do 45)"
    else if(fields[1] === "C")
      category = "Kat. C (od 46 do 55)"
    else if(fields[1] === "D")
      category = "Kat. D (od 56 do 65)"
    else if(fields[1] === "E")
      category = "Kat. E (od 66)"
    else 
      category = "Wybierz kategorię"

    infoDOM.innerHTML = `${sex} - ${category}`
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                    USERS                                   */
/* -------------------------------------------------------------------------- */
try {
  const choicesDisciplines = new Choices(".js-choice-disciplines", {
    itemSelectText: "Naciśnij, aby wybrać",
    noChoicesText: "Brak więcej opcji",
    duplicateItemsAllowed: false
  }).ajax(async callback => {
    const response = await axios.get("/api/disciplines")
    callback(response.data.items, "value", "label")
  })
} catch (e) {}

