import axios from "../vendors/axios"

const sendDataHandler = async ({ method, url, data, callback, type }) => {
  const csrfToken = document.querySelector("[name=_csrf]").getAttribute("content")

  const options = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/x-www-form-urlencoded",
      "CSRF-Token": encodeURIComponent(csrfToken)
    }
  }

  const response = await axios({ method, url, data: data, options })

  if (response.data.success == false || response.status > 300) {
    callback(false, response)
  } else {
    callback(true, response)
  }
}

export default sendDataHandler
