import Jodit from "jodit"

function showFormGroup(parent) {
  const parentDOM = document.querySelectorAll(parent)
  const triggerRadio = function(radio, parent, flag = true) {
    const hiddenForm = parent.querySelector(".form-group-hidden")

    if (radio.getAttribute("data-show-group") !== null && radio.checked == true) {
      hiddenForm.classList.add("is-shown")
    } else {
      flag && hiddenForm.classList.remove("is-shown")
    }
  }

  parentDOM.forEach(parent => {
    const radioButtons = parent.querySelectorAll("[type=radio]")

    // check for radios after first render
    radioButtons.forEach(radio => triggerRadio(radio, parent, false))

    radioButtons.forEach(radio =>
      radio.addEventListener("change", function() {
        triggerRadio(this, parent)
      })
    )
  })
}

function checkForEmptyInput(field) {
  const fieldDOM = document.querySelector(`[name="${field}"]`)
  fieldDOM.classList.add("is-invalid")

  ;["keyup", "change"].forEach(e => {
    fieldDOM.addEventListener(e, function() {
      if (this.value.length > 0) {
        this.classList.remove("is-invalid")
      } else {
        this.classList.add("is-invalid")
      }
    })
  })
}

const enableEditHandler = (item, form) => {
  const btnEditParent = item.closest(".js-btn-edit-parent")
  btnEditParent.classList.add("is-active")

	const title = btnEditParent.getAttribute('data-title')
	if(title){
		const btn = btnEditParent.querySelector('.btn')
		const prevTitle = btn.textContent
		btn.textContent = title
		btnEditParent.setAttribute('data-title', prevTitle)
	}

  const formDOM = document.querySelector(form)
  const formInputsDOM = formDOM.querySelectorAll(".is-uneditable")
  formInputsDOM.forEach(input => {
    input.classList.remove("is-uneditable")
  })
}

const disableEditing = (btnDOM, formDOM) => {
  const btnParentDOM = btnDOM.closest(".js-btn-edit-parent")
  btnParentDOM.classList.remove("is-active")

  const disabledElements = formDOM.querySelectorAll("[data-edit]")
  disabledElements.forEach(elem => elem.classList.add("is-uneditable"))
}

const checkToggleCheckboxes = form => {
  /** it could be an utility method, that would return node and checking if provided variable is node */
  let formDOM
  if (form instanceof Element || form instanceof HTMLDocument) {
    formDOM = form
  } else {
    formDOM = document.querySelector(form)
  }

  const allCheckboxes = formDOM.querySelectorAll('[type="checkbox"]')

  let mainCheckboxDOM
  allCheckboxes.forEach(ck => {
    if (ck.getAttribute("data-main-checkbox") !== null) {
      mainCheckboxDOM = ck
    }

    ck.addEventListener("change", event => {
      if (event.target.getAttribute("data-main-checkbox") !== null) {
        allCheckboxes.forEach(ck => (ck.checked = event.target.checked))

        // checkbox is checked
      } else if (event.target.checked) {
        /**
         * if all checkboxes were checked manually
         * then we should sign as checked the main checkbox
         */
        let flag = true
        allCheckboxes.forEach(ck => {
          if (ck.checked === false && !(ck.getAttribute("data-main-checkbox") !== null)) {
            flag = false
          }
        })

        if (flag) {
          mainCheckboxDOM.checked = event.target.checked
        }

        // checkbox is unchecked
      } else {
        mainCheckboxDOM.checked = event.target.checked
      }
    })
  })
}

const uncheckMainToggleCheckbox = form => {
  let formDOM
  if (form instanceof Element || form instanceof HTMLDocument) {
    formDOM = form
  } else {
    formDOM = document.querySelector(form)
  }

  const mainCheckbox = formDOM.querySelector("[data-main-checkbox]")
  mainCheckbox.checked = false
}

const removeDocument = id => {
  console.log(removeDocument)
  document.getElementById(id).remove()
}

const expandText = ({ wrapper }) => {
  const wrapperDOM = document.querySelectorAll(wrapper)

  wrapperDOM.forEach(wrapp => {
    const btnDOM = wrapp.querySelector(".js-expand-button")
    // const expandBoxDOM = wrapp.querySelector('.js-expand-box');

    btnDOM.addEventListener("click", () => wrapp.classList.toggle("is-expanded"))
  })
}

const previewPassword = item => {
  const inputDOM = item.parentNode.querySelector("input")
  inputDOM.type === "password" ? (inputDOM.type = "text") : (inputDOM.type = "password")
}

const editorsDOM = document.querySelectorAll("[data-wysiwyg]")
editorsDOM.forEach(editor => {
  let jodit = new Jodit(editor)
})

export default {
  showFormGroup,
  checkForEmptyInput,
  enableEditHandler,
  disableEditing,
  checkToggleCheckboxes,
  removeDocument,
  uncheckMainToggleCheckbox,
  expandText,
  previewPassword
}
