function msieversion() {
  var ua = window.navigator.userAgent
  var msie = ua.indexOf("MSIE ")

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    // If Internet Explorer, return version number
    alert(
      "Przeglądarka Internet Eksplorer nie obsługuje aplikacji MPS, ponieważ została wycofana przez firmę Microsoft ze względów bezpieczeństwa. Prosimy o skorzystanie z innych przeglądarek."
    )
  }

  return false
}
msieversion()

if ("NodeList" in window && !NodeList.prototype.forEach) {
  console.info("polyfill for IE11")
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }
}
