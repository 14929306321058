import Accordion from "../modules/accordion"
import forms from "../modules/forms"
import { ConfirmModal, StatusModal, UploadModal } from "../modules/modal"
import sendDataHandler from "../modules/sendDataHandler"
import UPDATEDsendDataHandler from "../modules/UPDATEDsendDataHandler"

let statusModal
let uploadModal
let confirmModal
try {
  statusModal = new StatusModal(".js-modal-container-status")
  uploadModal = new UploadModal(".js-modal-container-upload")
  confirmModal = new ConfirmModal(".js-modal-container-confirm")
} catch (e) {console.log(e)}

/**  Default callback */
const defaultCallback = (flag, response) => {
  if (!flag) {
    statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
    statusModal.toggleModal()
  } else {
    statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
    statusModal.toggleModal()
  }
}

/**
 * Adding Files
 */
try {
  const addFiles = document.querySelector(".js-add-files")
  const documentsDOM = document.querySelector(".js-documents")

  const callback = () => {
    uploadModal.toggleModal()

    const div = document.createElement("div")
    div.classList = "h-flex h-ai-c h-mt-2"
    div.id = "doc_" + uploadModal.selectedImage._id
    div.innerHTML = `
      <a href="/${uploadModal.selectedImage.filepath}" download><img src="/client/images/download.svg" alt="Pobierz" class="icon icon--sm"></a>
      <a href="/${uploadModal.selectedImage.filepath}" target="_blank" class="h-ml-2 link">${uploadModal.selectedImage.filename}</a>
      <img src="/client/images/bin.svg" alt="Kosz" class="icon icon--xs h-ml-2 icon--hover js-removable-document" data-remove="${uploadModal.selectedImage._id}">
      <input type="hidden" name="documents[]" value="${uploadModal.selectedImage._id}">
    `

    documentsDOM.appendChild(div)

    const removableDocmentsDOM = document.querySelectorAll(".js-removable-document")

    removableDocmentsDOM.forEach(doc => doc.addEventListener("click", event => forms.removeDocument("doc_" + event.currentTarget.getAttribute("data-remove"))))
  }

  addFiles.addEventListener("click", () => {
    uploadModal.toggleModal({ callbackOnModalClose: callback })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                 LOGIN ADMIN                                */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-admin-login-btn")
  const formDOM = document.querySelector(btn.getAttribute("data-form"))

  btn.addEventListener("click", e => {
    e.preventDefault()

    formDOM.classList.add("is-loading")
    formDOM.classList.remove("is-failed")
    formDOM.classList.remove("is-success")

    const callback = (flag, response) => {
      formDOM.classList.remove("is-loading")

      if (response.data.success) {
        formDOM.classList.add("is-success")
        setTimeout(function() {
          window.location.href = "/"
        }, 1000)
      } else {
        formDOM.classList.add("is-failed")
        formDOM.querySelector(".error-message").innerHTML = response.data.message
      }
    }

    const data = new FormData(formDOM)

    sendDataHandler({
      method: "POST",
      url: "/api/admin/login",
      data,
      callback
    })
  })
} catch (e) {}

/**
 * Send Message
 */

try {
  const sendMessage = document.querySelector(".js-send-message")
  sendMessage.addEventListener("click", () => {
    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const data = new FormData(document.querySelector(".js-form-message"))

    UPDATEDsendDataHandler({
      method: "post",
      url: "/api/admin/message",
      data,
      callback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                     FAQ                                    */
/* -------------------------------------------------------------------------- */
try {
  const accordion_faq = new Accordion(".js-accordion", {
    items: ".js-accordion__header"
  })

  const createButton = document.querySelector(".js-submit-create-faq")

  createButton.addEventListener("click", () => {
    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()

        const item = document.createElement("li")
        item.classList = "accordion__item h-mt-1rem"
        item.innerHTML = renderItem(response.data.saved)
        document.querySelector(".js-faq-wrapper").appendChild(item)

        accordion_faq.recache()
      }
    }

    const data = new FormData(document.querySelector(".js-form-create-faq"))

    const renderItem = item => {
      return `
      <li class="accordion__item h-mt-1rem">
        <header class="accordion__header js-accordion__header">
          <div class="accordion__icon"></div>
          <div class="accordion__heading">${item.title}</div>
        </header>
        <div class="accordion__body">
          <div class="accordion__desc">${item.content}</div>
        </div>
      </li>`
    }

    sendDataHandler({
      method: "post",
      url: "/api/admin/faq",
      data,
      callback
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                              CHANGE PASSWORD                               */
/* -------------------------------------------------------------------------- */
try {
  const changePasswordButton = document.querySelector(".js-change-password-button")
  changePasswordButton.addEventListener("click", e => {
    e.preventDefault()
    const formDOM = document.querySelector(changePasswordButton.getAttribute("data-form"))
    const formWrapper = document.querySelector(".form-wrapper")

    formWrapper.classList.add("is-loading")
    formWrapper.classList.remove("failed")
    formWrapper.classList.remove("success")

    const callback = (flag, response) => {
      formWrapper.classList.remove("is-loading")

      if (response.data.success) {
        formWrapper.classList.add("success")
        setTimeout(function() {
          window.location.href = "/logowanie"
        }, 1000)
      } else {
        formWrapper.classList.add("failed")
        document.querySelector(".error-message").innerHTML = response.data.message
      }
    }

    const data = new FormData(formDOM)
    let url = "/logowanie/zmien-haslo"

    sendDataHandler({
      method: "POST",
      url,
      data,
      callback
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                              FORGOT PASSWORD                               */
/* -------------------------------------------------------------------------- */
try {
  const changePasswordButton = document.querySelector(".js-forgot-pass-button")
  changePasswordButton.addEventListener("click", e => {
    e.preventDefault()
    const formDOM = document.querySelector(changePasswordButton.getAttribute("data-form"))
    const formWrapper = document.querySelector(".form-wrapper")

    formWrapper.classList.add("is-loading")
    formWrapper.classList.remove("failed")
    formWrapper.classList.remove("success")

    const callback = (flag, response) => {
      formWrapper.classList.remove("is-loading")

      if (response.data.success) {
        formWrapper.classList.add("success")
      } else {
        formWrapper.classList.add("failed")
        document.querySelector(".error-message").innerHTML = response.data.message
      }
    }

    const data = new FormData(formDOM)
    let url = "/logowanie/przypomnij-haslo"

    sendDataHandler({
      method: "POST",
      url,
      data,
      callback
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                               REPORT PROBLEM                               */
/* -------------------------------------------------------------------------- */
try {
  const sendProblem = document.querySelector(".js-submit-create-problem")

  sendProblem.addEventListener("click", () => {
    let formDOM = document.querySelector(sendProblem.getAttribute("data-form"))

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const data = new FormData(formDOM)

    sendDataHandler({
      method: "post",
      url: `/api/report-problem`,
      data,
      callback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                            SEND MESSAGE AS USER                            */
/* -------------------------------------------------------------------------- */
try {
  const sendMessage = document.querySelector(".js-send-message-user")
  sendMessage.addEventListener("click", () => {
    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const data = new FormData(document.querySelector(".js-form-message"))

    sendDataHandler({
      method: "post",
      url: "/wiadomosci/napisz-wiadomosc",
      data,
      callback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                FIRST LOGIN                                 */
/* -------------------------------------------------------------------------- */
try {
  const firstLogin = document.querySelector(".js-first-login")

  if (firstLogin) {
    const data = new FormData()
    data.append("firstLogin", true)

    setTimeout(() => {
      sendDataHandler({
        method: "post",
        url: "/api/user",
        data,
        callback: () => {}
      })
    }, 5000)
  }
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                  DONATION                                  */
/* -------------------------------------------------------------------------- */

try {
  const btn = document.querySelector(".js-payment-btn")
  const form = document.querySelector(".js-payment-form")

  btn.addEventListener("click", () => {
    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Uwaga`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        window.location.href = response.data.link
      }
    }

    const data = new FormData(form)

    sendDataHandler({
      method: "post",
      url: "/api/payment",
      data,
      callback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                             SIGN TO DISCIPLINE                             */
/* -------------------------------------------------------------------------- */
try {
  const btns = document.querySelectorAll(".js-sign-to-discipline")
  const callback = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Uwaga`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      window.location.href = "/moje-dyscypliny"
    }
  }

  btns.forEach(btn => {
    btn.addEventListener("click", () => {
      const data = new FormData()
      data.append("disciplineId", btn.getAttribute("data-discipline"))

      sendDataHandler({
        method: "post",
        url: `/api/sign-to-discipline`,
        data,
        callback
      })
    })
  })
} catch (err) {}
/* -------------------------------------------------------------------------- */
/*                                 CREATE UNIT                                */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-create-unit-btn")
  const form = document.querySelector(".js-create-unit-form")

  btn.addEventListener("click", () => {
    const data = new FormData(form)

    sendDataHandler({
      method: "post",
      url: `/api/unit`,
      data,
      callback: defaultCallback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                 UPDATE UNIT                                */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-update-unit-btn")
  const form = document.querySelector(".js-update-unit-form")

  btn.addEventListener("click", () => {
    const data = new FormData(form)
    const id = btn.getAttribute("data-id")

    sendDataHandler({
      method: "put",
      url: `/api/unit/${id}`,
      data,
      callback: defaultCallback
    })
  })
} catch (err) {}
/* -------------------------------------------------------------------------- */
/*                                 DELETE UNIT                                */
/* -------------------------------------------------------------------------- */
window.removeUnit = (elem, id) => {
  const callback = (flag, response) => {
    if (!flag) {
      confirmModal.injectHTML({ title: `Błąd`, content: response.data.message })
      confirmModal.setFailed()
    } else {
      confirmModal.injectHTML({ title: `Sukces`, content: response.data.message })
      confirmModal.setSuccess()
      document.getElementById(id).remove()
    }
  }

  const confirmCallback = () => {
    confirmModal.setLoading()
    sendDataHandler({
      method: "delete",
      url: `/api/unit/${id}`,
      callback: callback
    })
  }

  confirmModal.injectHTML({ title: `Potwierdź`, content: "Czy na pewno usunąć Jednostkę?", confirmCallback })
  confirmModal.toggleModal()
}
/* -------------------------------------------------------------------------- */
/*                                CREATE JUDGE                                */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-create-judge-btn")
  const form = document.querySelector(".js-create-judge-form")

  btn.addEventListener("click", () => {
    const data = new FormData(form)

    UPDATEDsendDataHandler({
      method: "post",
      url: `/api/judge`,
      data,
      callback: defaultCallback
    })
  })
} catch (err) {}
/* -------------------------------------------------------------------------- */
/*                                UPDATE JUDGE                                */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-update-judge-btn")
  const form = document.querySelector(".js-update-judge-form")

  btn.addEventListener("click", () => {
    const data = new FormData(form)
    const id = btn.getAttribute("data-id")

    UPDATEDsendDataHandler({
      method: "put",
      url: `/api/judge/${id}`,
      data,
      callback: defaultCallback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                DELETE JUDGE                                */
/* -------------------------------------------------------------------------- */
window.removeJudge = (elem, id) => {
  const callback = (flag, response) => {
    if (!flag) {
      confirmModal.injectHTML({ title: `Błąd`, content: response.data.message })
      confirmModal.setFailed()
    } else {
      confirmModal.injectHTML({ title: `Sukces`, content: response.data.message })
      confirmModal.setSuccess()
      document.getElementById(id).remove()
    }
  }

  const confirmCallback = () => {
    confirmModal.setLoading()
    sendDataHandler({
      method: "delete",
      url: `/api/judge/${id}`,
      callback: callback
    })
  }

  confirmModal.injectHTML({ title: `Potwierdź`, content: "Czy na pewno usunąć Sędziego?", confirmCallback })
  confirmModal.toggleModal()
}

/* -------------------------------------------------------------------------- */
/*                                 CREATE USER                                */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-create-user-btn")
  const form = document.querySelector(".js-create-user-form")

  btn.addEventListener("click", () => {
    const data = new FormData(form)

    sendDataHandler({
      method: "post",
      url: `/api/participant`,
      data,
      callback: defaultCallback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                 UPDATE USER                                */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-update-user-btn")
  const form = document.querySelector(".js-update-user-form")

  btn.addEventListener("click", () => {
    const data = new FormData(form)
    const id = btn.getAttribute("data-id")

    sendDataHandler({
      method: "patch",
      url: `/api/user/${id}`,
      data,
      callback: defaultCallback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                           RESEND ACTIVATION TOKEN                          */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-resend-token-btn")

  btn.addEventListener("click", () => {
    const data = new FormData()
    const id = btn.getAttribute("data-id")
    data.append("id", id)

    sendDataHandler({
      method: "put",
      url: `/api/user/resend-token`,
      data,
      callback: defaultCallback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                             UPDATE USER AS USER                            */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-c-update-user-btn")
  const form = document.querySelector(".js-c-update-user-form")

	const callback = (flag, response) => {
		if (!flag) {
			statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
			statusModal.toggleModal()
		} else {
			statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
			statusModal.toggleModal();
			window.location.reload();
		}
	}

  btn.addEventListener("click", () => {
    const data = new FormData(form)

    sendDataHandler({
      method: "put",
      url: `/api/user`,
      data,
      callback: callback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                 DELETE User                                */
/* -------------------------------------------------------------------------- */
window.removeUser = (elem, id) => {
  const callback = (flag, response) => {
    if (!flag) {
      confirmModal.injectHTML({ title: `Błąd`, content: response.data.message })
      confirmModal.setFailed()
    } else {
      confirmModal.injectHTML({ title: `Sukces`, content: response.data.message })
      confirmModal.setSuccess()
      document.getElementById(id).remove()
    }
  }

  const confirmCallback = () => {
    confirmModal.setLoading()
    sendDataHandler({
      method: "delete",
      url: `/api/user/${id}`,
      callback: callback
    })
  }

  confirmModal.injectHTML({ title: `Potwierdź`, content: "Czy na pewno usunąć Uczestnika?", confirmCallback })
  confirmModal.toggleModal()
}

//user
/* -------------------------------------------------------------------------- */
/*                                    LOGIN                                   */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-login-btn")
  const formDOM = document.querySelector(btn.getAttribute("data-form"))

  btn.addEventListener("click", e => {
    e.preventDefault()

    formDOM.classList.add("is-loading")
    formDOM.classList.remove("is-failed")
    formDOM.classList.remove("is-success")

    const callback = (flag, response) => {
      formDOM.classList.remove("is-loading")

      if (response.data.success) {
        formDOM.classList.add("is-success")
        setTimeout(function() {
          if (btn.getAttribute("data-url") == "user") {
            window.location.href = "/"
          } else {
            window.location.href = "/admin"
          }
        }, 1000)
      } else {
        formDOM.classList.add("is-failed")
        formDOM.querySelector(".error-message").innerHTML = response.data.message
      }
    }

    const data = new FormData(formDOM)

    sendDataHandler({
      method: "POST",
      url: "/api/login",
      data,
      callback
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                                   SIGNUP                                   */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-signup-btn")
  btn.addEventListener("click", e => {
    e.preventDefault()
    const formDOM = document.querySelector(btn.getAttribute("data-form"))

    formDOM.classList.add("is-loading")
    formDOM.classList.remove("is-failed")
    formDOM.classList.remove("is-success")

    const callback = (flag, response) => {
      formDOM.classList.remove("is-loading")

      if (response.data.success) {
        formDOM.classList.add("is-success")
        document.querySelector(".success-message").innerHTML = response.data.message
      } else {
        formDOM.classList.add("is-failed")
        if (response.data.failedFields) {
          const failedFields = response.data.failedFields

          const formGroupAll = formDOM.querySelectorAll(".is-failed")
          formGroupAll.forEach(f => f.classList.remove("is-failed"))

          failedFields.forEach(field => {
            const fieldName = Object.keys(field)[0]
            const formGroupDOM = formDOM.querySelector(`[name="${fieldName}"]`).closest(".form-group")
            if (formGroupDOM) formGroupDOM.classList.add("is-failed")
          })

          document.querySelector(".error-message").innerHTML = failedFields[0][Object.keys(failedFields[0])[0]]
        } else {
          document.querySelector(".error-message").innerHTML = response.data.message
        }
      }
    }

    const data = new FormData(formDOM)

    sendDataHandler({
      method: "POST",
      url: "/api/signup",
      data,
      callback
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                              FORGOT PASSWORD                               */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-retrieve-pass-btn")
  const formDOM = document.querySelector(btn.getAttribute("data-form"))

  btn.addEventListener("click", e => {
    e.preventDefault()

    formDOM.classList.add("is-loading")
    formDOM.classList.remove("is-failed")
    formDOM.classList.remove("is-success")

    const callback = (flag, response) => {
      formDOM.classList.remove("is-loading")

      if (response.data.success) {
        formDOM.classList.add("is-success")
				
      } else {
        formDOM.classList.add("is-failed")
        document.querySelector(".error-message").innerHTML = response.data.message
      }
    }

    const data = new FormData(formDOM)

    sendDataHandler({
      method: "POST",
      url: "/api/retrieve-password",
      data,
      callback
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                              FORGOT PASSWORD                               */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-change-pass-btn")
  const formDOM = document.querySelector(btn.getAttribute("data-form"))

  btn.addEventListener("click", e => {
    e.preventDefault()

    formDOM.classList.add("is-loading")
    formDOM.classList.remove("is-failed")
    formDOM.classList.remove("is-success")

    const callback = (flag, response) => {
      formDOM.classList.remove("is-loading")

      if (response.data.success) {
        formDOM.classList.add("is-success")
				window.location.href = "/"
      } else {
        formDOM.classList.add("is-failed")
        document.querySelector(".error-message").innerHTML = response.data.message
      }
    }

    const data = new FormData(formDOM)

    sendDataHandler({
      method: "POST",
      url: "/api/change-password",
      data,
      callback
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                                 SEND FAQ                                   */
/* -------------------------------------------------------------------------- */
try {
  const btnDOM = document.querySelector(".js-create-question-btn")
  const formDOM = document.querySelector(".js-create-question-form")

  btnDOM.addEventListener("click", e => {
    e.preventDefault()

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const data = new FormData(formDOM)

    sendDataHandler({
      method: "POST",
      url: "/api/faq",
      data,
      callback
    })
  })
} catch (e) {}

//judge
/* -------------------------------------------------------------------------- */
/*                                    LOGIN                                   */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-login-judge-btn")
  const formDOM = document.querySelector(btn.getAttribute("data-form"))

  btn.addEventListener("click", e => {
    e.preventDefault()

    formDOM.classList.add("is-loading")
    formDOM.classList.remove("is-failed")
    formDOM.classList.remove("is-success")

    const callback = (flag, response) => {
      formDOM.classList.remove("is-loading")

      if (response.data.success) {
        formDOM.classList.add("is-success")
        setTimeout(function() {
          window.location.href = "/"
        }, 1000)
      } else {
        formDOM.classList.add("is-failed")
        formDOM.querySelector(".error-message").innerHTML = response.data.message
      }
    }

    const data = new FormData(formDOM)

    sendDataHandler({
      method: "POST",
      url: "/judge/api/login",
      data,
      callback
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                        UPDATE DISCIPLINE PUNCTATION                        */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-update-punctation-btn")
  const form = document.querySelector(".js-update-punctation-form")
  btn.addEventListener("click", () => {
    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const data = new FormData(form)

    let individualPoints = [],
      groupPoints = []

    for (var pair of data.entries()) {
      if (pair[0].includes("individualPoints[]")) {
        individualPoints.push(pair[1])
      }
      if (pair[0].includes("groupPoints[]")) {
        groupPoints.push(pair[1])
      }
    }

    data.append("individualPoints", individualPoints.join(","))
    data.append("groupPoints", groupPoints.join(","))

    sendDataHandler({
      method: "put",
      url: "/api/admin/discipline",
      data,
      callback
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                              UPDATE USER PLACE                             */
/* -------------------------------------------------------------------------- */
window.updateUserPlace = item => {
  const callback = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
      statusModal.toggleModal()
    }
  }
  
  item.classList.remove('has-changed')
  const inputPlaceDOM = item.parentNode.querySelector('[name=place]');
  const inputDescriptionDOM = item.parentNode.querySelector('[name=pointsDescription]');

  const userId = inputPlaceDOM.getAttribute('data-userId')
  const disciplineSlug = inputPlaceDOM.getAttribute('data-disciplineSlug')

  const data = new FormData();
  data.append('place', inputPlaceDOM.value)
  data.append('pointsDescription', inputDescriptionDOM.value)

  sendDataHandler({
    method: "patch",
    url: `/api/user/${userId}/discipline/${disciplineSlug}`,
    data,
    callback
  })
}
/* -------------------------------------------------------------------------- */
/*                              UPDATE UNIT PLACE                             */
/* -------------------------------------------------------------------------- */
window.updateUnitPlace = item => {
  const callback = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
      statusModal.toggleModal()
    }
  }
  
  item.classList.remove('has-changed')
  const inputPlaceDOM = item.parentNode.querySelector('[name=place]');

  const unitId = inputPlaceDOM.getAttribute('data-unitId')
  const disciplineSlug = inputPlaceDOM.getAttribute('data-disciplineSlug')

  const data = new FormData();
  data.append('place', inputPlaceDOM.value)

  sendDataHandler({
    method: "patch",
    url: `/api/unit/${unitId}/discipline/${disciplineSlug}`,
    data,
    callback
  })
}

try{
  const btn = document.querySelector(".js-publish-scores-btn")
  btn.addEventListener("click", () => {
    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
        if(response.data.isPublished){
          btn.classList.remove('btn--red');
          btn.innerHTML = "Ukryj wyniki"
        }else{
          btn.classList.add('btn--red');
          btn.innerHTML = "Opublikuj wyniki"
        }
      }
    }

    const data = new FormData()
    const disciplineSlug = btn.getAttribute('data-discipline')
    data.append('discipline', disciplineSlug)

    sendDataHandler({
      method: "patch",
      url: "/api/discipline/publish",
      data,
      callback
    })
  })
}catch(err){
	console.log(err)
}


try {
	const btn = document.querySelector(".js-update-punctation-btn")
	const form = document.querySelector(".js-update-punctation-form")
	btn.addEventListener("click", () => {
		const callback = (flag, response) => {
			if (!flag) {
				statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
				statusModal.toggleModal()
			} else {
				statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
				statusModal.toggleModal()
			}
		}

		const data = new FormData(form)

		let individualPoints = [],
			groupPoints = []

		for (var pair of data.entries()) {
			if (pair[0].includes("individualPoints[]")) {
				individualPoints.push(pair[1])
			}
			if (pair[0].includes("groupPoints[]")) {
				groupPoints.push(pair[1])
			}
		}

		data.append("individualPoints", individualPoints.join(","))
		data.append("groupPoints", groupPoints.join(","))

		sendDataHandler({
			method: "put",
			url: "/api/admin/discipline",
			data,
			callback
		})
	})
} catch (err) {}

window.putSubmit = async function (event) {
	event.preventDefault()
	const form = event.target;
	const data = new FormData(form)
	const href = form.getAttribute("action")

	const callback = (flag, response) => {
		if (!flag) {
			statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
			statusModal.toggleModal()
		} else {
			statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
			statusModal.toggleModal()
		}
		if(response.data.refreshPage){ window.location.reload() }
	}


	sendDataHandler({
		method: "PUT",
		url: href,
		data,
		callback
	})
}

window.deleteSubmit = async function (event) {
	event.preventDefault()
	const form = event.target;
	const data = new FormData(form)
	const href = form.getAttribute("action")

	const callback = (flag, response) => {
		if (!flag) {
			statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
			statusModal.toggleModal()
		} else {
			statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
			statusModal.toggleModal()
		}

		if(response.data.refreshPage){ window.location.reload() }
	}

	sendDataHandler({
		method: "DELETE",
		url: href,
		data,
		callback
	})
}












window.showAlert = (event, title, content) => {
	event.preventDefault();
	statusModal.injectHTML({ title, content })
	statusModal.toggleModal()
}