import pushUrl from '../methods/pushUrl';
import updateURLParameter  from '../methods/updateUrlParameter';
import debounce from '../methods/debounce';


export default class PhraseSearch {
  constructor(wrapperDOM){
    this.wrapperDOM = document.querySelector(wrapperDOM);
    this.inputDOM = this.wrapperDOM.querySelector('input');
    this.triggerDOM = document.querySelector('.js-trigger-search')

    this._bindEvents();
  }

  _bindEvents(){
    this.keyUpDebounce = debounce(this.keyUpEvent, 200);
    this.submitDebounce = debounce(this.submitEvent, 200);
    this.inputDOM.addEventListener('keyup', (event) => this.keyUpDebounce(event))
    this.triggerDOM.addEventListener('click', () => this.submitDebounce())
  }

  submitEvent(){
    let newURL = updateURLParameter(window.location.href, 'phrase', this.inputDOM.value);
    newURL = updateURLParameter(newURL, 'page', 1);
    pushUrl(newURL);
  }

  keyUpEvent(event){
    if(event.keyCode === 13) return this.submitEvent();
  }
}