import sendDataHandler from './sendDataHandler';
import debounce from '../methods/debounce';

/**
 * Instantiate Notifications
 * @param  {Node}       wrapper Dom class
 * @param  {Object}     options Object holding optional parameters
 * @return {undefined}
 */
export default class Notifications{
  constructor(wrapper, options = {}) {
    this.wrapperDOM = document.querySelector(wrapper);
    if(!this.wrapperDOM) throw new Error('You must specify Notifications wrapper!');

    this.togglerDOM = (options.toggler) ? document.querySelector(options.toggler) : [];
    this.listItems = options.notificationItems;
    this.listItemsDeleteDOM = this.wrapperDOM.querySelectorAll(options.notificationItemDelete);
    this.notificationDeleteAllDOM = this.wrapperDOM.querySelector(options.notificationDeleteAll) || null;
    this.notificationScrollDOM = this.wrapperDOM.querySelector(options.notificationScroll) || null;
    this.notificationLoaderDOM = this.wrapperDOM.querySelector(options.notificationLoader) || null;
    this.notificationNotReadCounters = document.querySelectorAll('.js-notification-notread-counter');

    this.notificationScrollDebounce;
    /** Indicates if all notifications were loaded */
    this.notificationEndFlag = false;

    this.notificationNotReadCount = this.notificationNotReadCounters[0].innerHTML;
    this.notificationLoadedCount = document.querySelectorAll(this.listItems).length;
    this.notificationFinallyCount = this.wrapperDOM.getAttribute('data-count');

    this._bindEvents();
    this.boundClickOutsideEvent;
    this.boundScrollEvent;
  }

  toggleNotifications(flag){
    if(flag == true){
      this.wrapperDOM.classList.add('is-active');
      this.togglerDOM.classList.add('is-active');

      this._bindEventsWhenVisible();
    }else if(flag == false){
      this.wrapperDOM.classList.remove('is-active');
      this.togglerDOM.classList.remove('is-active');
      document.removeEventListener('click', this.boundClickOutsideEvent, true);
      document.removeEventListener('scroll', this.boundScrollEvent, true);
    }
  }

  _bindEvents(){
    this.togglerDOM.addEventListener('click', () => this.toggleNotifications(true));
    this.listItemsDeleteDOM.forEach(listItemDelete => { listItemDelete.addEventListener('click', (event) => this.markAsRead(event))});

    (this.notificationDeleteAllDOM) && this.notificationDeleteAllDOM.addEventListener('click', () => this.markAsReadAll());
  }

  _bindEventsWhenVisible(){
    this.boundClickOutsideEvent = (event) => this.clickOutside(event);
    document.addEventListener('click', this.boundClickOutsideEvent, true);

    this.boundScrollEvent = (event) => this.loadMore(event);

    this.notificationScrollDebounce = debounce(this.boundScrollEvent, 50);
    this.notificationScrollDOM.addEventListener('scroll', this.notificationScrollDebounce);
  }

  clickOutside(event){
    /** Prevents to call this function more then one in such event */
    // event.stopImmediatePropagation();
    // event.stopPropagation();
    // event.cancelBubble=true

    /** if click happends outside of wrapperDOM then close notifications */
    if (!this.wrapperDOM.contains(event.target)) {
      this.toggleNotifications(false);
    }
  }

  markAsRead(event){
    const clickedListItem = event.target.closest(this.listItems);
    const clickedListItemId = clickedListItem.getAttribute('data-id');

    const callback = (success) => {
      if(success){
        clickedListItem.classList.remove('is-active');
        this.notificationNotReadCount -= 1;
        this.changeNotReadCounter();
      }
    }

    sendDataHandler({
      method: 'put',
      url: `/api/notification/${clickedListItemId}`,
      callback
    });
  }

  markAsReadAll(){
    if(this.notificationNotReadCount != 0){
      const callback = (success, response) => {
        if(success){
          document.querySelectorAll(this.listItems).forEach(li => {
            li.classList.remove('is-active');
          })
  
          this.notificationNotReadCount = 0;
          this.changeNotReadCounter();
        }
      }
  
      sendDataHandler({
        method: 'put',
        url: `/api/notifications`,
        callback
      });
    }
  }

  loadMore(event){
    event.stopImmediatePropagation();

    const callback = (success, response) => {
      if(!success){
        // TODO: Status modal should be instantiated
        //!
        return;
      }

      const notificationItems = response.data.items;

      notificationItems.map(item => {
        const render = this.renderNotificationItem(item);
        if(render){
          this.notificationScrollDOM.append(render);
          this.notificationLoadedCount+=1;
        }
        
        /** if there were loaded all notifications set flag to true */
        if(this.notificationLoadedCount == this.notificationFinallyCount){
          this.notificationEndFlag = true
        }
      });

      setTimeout(() => {
        this.toggleLoader(false);
      }, 500);
    }

    let reachedBottom = this.notificationScrollDOM.offsetHeight + this.notificationScrollDOM.scrollTop >= this.notificationScrollDOM.scrollHeight;

    /** when reached bottom and didn't loaded all notifies yet */
    if(reachedBottom && !this.notificationEndFlag){
      this.toggleLoader(true);

      const allItems = document.querySelectorAll('.js-notification-item');

      sendDataHandler({
        method: 'get',
        url: `/api/notification?limit=4&skip=${allItems.length}`,
        callback
      });
    }
  }

  toggleLoader(flag){
    (flag) ? this.notificationLoaderDOM.classList.add('is-active') : this.notificationLoaderDOM.classList.remove('is-active');
  }

  changeNotReadCounter() {
    this.notificationNotReadCounters.forEach(counter => {
      counter.innerHTML = this.notificationNotReadCount
    });
  }

  renderNotificationItem(item){
    let isNotRead = "", isLinked = "";
    if(item.read == false) isNotRead = "is-active";
    if(item.link) isLinked = `href="${item.link}"`;

    const node = document.createElement('div');
    node.setAttribute('data-id', item._id);
    node.classList = `notification-item js-notification-item ${isNotRead}`;

    node.innerHTML = `
        <a ${isLinked} title="Przejdź" class="notification-item__text">${item.title}</a>
        <div class="notification-delete js-notification-delete h-hover">
          <img src="/client/images/archive.svg" alt="" class="icon h-mr-1">
        </div>
      `;

    node.querySelector('.js-notification-delete').addEventListener('click', event => this.markAsRead(event))

    return node;
  }
}