import sendDataHandler from './sendDataHandler';
import debounce from '../methods/debounce';

class Modal {
	constructor(modalContainerDOM) {
		this.modalContainerDOM = document.querySelector(modalContainerDOM);
		this.modalCancelDOM = this.modalContainerDOM.querySelectorAll('.js-cancel');
		this.modalTitleDOM = this.modalContainerDOM.querySelector('.js-title');
		this.modalContentDOM = this.modalContainerDOM.querySelector('.js-content');

		this.callbackOnModalClose;
	}

	toggleModal({callbackOnModalClose} = {}){
		this.modalContainerDOM.classList.toggle('is-active');

		if(callbackOnModalClose && typeof callbackOnModalClose === "function"){
			this.callbackOnModalClose = callbackOnModalClose;
		}
	}
}


class StatusModal extends Modal {
	constructor(modalContainerDOM) {
		super(modalContainerDOM);

		this._bindEvents();
	}

	_bindEvents(){
		this.modalCancelDOM.forEach(cancel => cancel.addEventListener('click', () => this.toggleModal()));
	}

	injectHTML({ title, content }){
		this.modalTitleDOM.innerHTML = title;
		this.modalContentDOM.innerHTML = content;
	}

	redirect({ url, timeout }){
		setTimeout(() => window.location.href = url, timeout);
	}
}


class UploadModal extends Modal {
	constructor(modalContainerDOM) {
		super(modalContainerDOM);
		this.loaderWrapperDOM = this.modalContainerDOM.querySelector('.js-loader-wrapper');
		
		this.files = [];

		this.formDOM = this.modalContainerDOM.querySelector('form');
		this.modalGalleryDOM = this.modalContainerDOM.querySelector('.modal-gallery');
		this.modalGalleryItemsDOM;

		this.proceedButtonDOM = this.modalContainerDOM.querySelector('.js-proceed');
		this.loadMoreButtonDOM = this.modalContainerDOM.querySelector('.js-load-more');
		this.selectImageButtonDOM = this.modalContainerDOM.querySelector('.js-select-image');

		this.galleryScrollDebounce;
    /** Indicates if all images were loaded */
		this.galleryEndFlag = false;
		this.boundScrollEvent;
		this.initialLoad = false;

		this.limit = 60
		this.skip = 0

		this.selectedImage = {};

		this._bindEvents();
	}

	_bindEvents(){
		this.modalCancelDOM.forEach(cancel => cancel.addEventListener('click', () => this.toggleModal()));
		this.proceedButtonDOM.addEventListener('click', () => this.uploadFile());
		this.loadMoreButtonDOM.addEventListener('click', (event) => this.loadFiles(event));
		this.selectImageButtonDOM.addEventListener('click', () => this.chooseImage());

		this.boundScrollEvent = (event) => this.loadFiles(event);
		this.galleryScrollDebounce = debounce(this.boundScrollEvent, 50);
    // this.modalGalleryDOM.addEventListener('scroll', this.galleryScrollDebounce);
	}

	injectHTML({ title, content }){
		this.modalTitleDOM.innerHTML = title;
		this.modalContentDOM.innerHTML = content;
	}

	uploadFile(){
		const inputDOM = this.formDOM.querySelector('[type=file]');

		const callback = (flag, response) => {
			if(!flag){
				/**
				 * TODO: Handle ERROR
				 */
				console.log('Uploading failed, message:', response.data.message);
			}else{
				/** Reset items in gallery view */
				this.files = [];
				this.initialLoad = false;
				this.galleryEndFlag = false;
				this.skip = 0

				/** Load them again */
				this.loadFiles();
				inputDOM.value = "";
			}
    }

    	const data = new FormData(this.formDOM);

		if(inputDOM.value !== ""){
			sendDataHandler({
				method: 'post',
				url: '/admin/upload-file',
				data,
				type: 'files',
				callback
			});
		}
	}

	loadFiles(event){
		if(event) event.stopImmediatePropagation();

		const callback = (flag, response) => {
			if(response.data.files.length >= this.limit)
				this.skip += this.limit
			else
				this.galleryEndFlag = true

			this.files = [...this.files, ...response.data.files];
			this.initialLoad = true;
			this.loaderWrapperDOM.classList.remove('is-active');
			this.injectFilesHMTL();
		}

		let reachedBottom = this.modalGalleryDOM.offsetHeight + this.modalGalleryDOM.scrollTop >= this.modalGalleryDOM.scrollHeight;

		const query = `?size=--xs&limit=${this.limit}&skip=${this.skip}`;

		if((this.initialLoad === false || reachedBottom) && !this.galleryEndFlag){
			this.loaderWrapperDOM.classList.add('is-active');

			sendDataHandler({
				method: 'get',
				url: '/api/files' + query,
				type: 'files',
				callback
			});
		}
	}

	injectFilesHMTL(){
		const fileHTML = (file) => {
			let img = file.path
			if(file.extension === "pdf") img = "client/images/pdf.svg"
			return `<div class="modal-gallery__item" id="${file._id}" data-extension="${file.extension}" data-filename="${file.originalFilename}" data-filepath="${file.path}">
				<img src="/${img}" alt="image">
				<div>${file.originalFilename}</div>
			</div>`;
		}

		const allFilesHTML = this.files.map(file => {
			return fileHTML(file);
		})

		this.modalGalleryDOM.innerHTML = allFilesHTML.join('');

		this.modalGalleryItemsDOM = this.modalGalleryDOM.querySelectorAll('.modal-gallery__item');
		this.modalGalleryItemsDOM.forEach(item => item.addEventListener('click', (event) => this.markSelectedImage(event)))
	}

	markSelectedImage(){
		this.modalGalleryItemsDOM.forEach(item => item.classList.remove('is-active'));
		event.currentTarget.classList.add('is-active');
	}

	chooseImage(){
		const target = this.modalGalleryDOM.querySelector('.modal-gallery__item.is-active');
		this.selectedImage = {
			_id: target.getAttribute('id'),
			extension: target.getAttribute('data-extension'),
			filename: target.getAttribute('data-filename'),
			filepath: target.getAttribute('data-filepath')
		}

		if(this.callbackOnModalClose !== ""){
			this.callbackOnModalClose();
		}
	}
}

class ConfirmModal extends Modal{
	constructor(modalContainerDOM) {
		super(modalContainerDOM);
		this.modalConfirmBtnDOM = this.modalContainerDOM.querySelector('.js-proceed');
		this.modalLoadableDOM = this.modalContainerDOM.querySelector('.js-loadable');

		this._bindEvents();
		this.confirmCallback;
	}

	_bindEvents(){
		this.modalCancelDOM.forEach(cancel => cancel.addEventListener('click', () => this.toggleModal()));
		this.modalConfirmBtnDOM.addEventListener('click', () => this.confirmAction());
	}

	confirmAction(){
		this.confirmCallback();
	}

	setLoading(){
		this.modalLoadableDOM.classList.add('is-loading');
	}
	
	setSuccess(){
		this.modalLoadableDOM.classList.remove('is-loading');
		this.modalLoadableDOM.classList.add('is-success');
	}
	
	setFailed(){
		this.modalLoadableDOM.classList.remove('is-loading');
		this.modalLoadableDOM.classList.add('is-failed');
	}

	injectHTML({ title, content, confirmCallback }){
		// reset state
		this.modalLoadableDOM.classList.remove('is-loading');
		this.modalLoadableDOM.classList.remove('is-success');
		this.modalLoadableDOM.classList.remove('is-failed');

		this.modalTitleDOM.innerHTML = title;
		this.modalContentDOM.innerHTML = content;
		this.confirmCallback = confirmCallback;
	}
}


export {
	Modal,
	StatusModal,
	UploadModal,
	ConfirmModal
}