import axios from '../vendors/axios';
import updateURLParameter  from '../methods/updateUrlParameter';
import getURLParameter  from '../methods/getUrlParameter';
import removeDuplicates  from '../methods/removeDuplicates';

class Search{
  constructor(inputGroupDOM, options = {}){
    this.inputGroupDOM = document.querySelector(inputGroupDOM);
    this.inputDOM = this.inputGroupDOM.querySelector('input');
    this.listWrapperDOM;
    this.list = [];
    this.listShow = false;
    this.activeListItem;
    this.listItemsLimit = options.listItemsLimit || 6;
    this.fetchApi = options.fetchApi;

    /** Fetch table loading */
    this.loadable = options.loadable;
    this.loadableClass = options.loadableClass;
    this.tableDOM = document.querySelector(options.tableDOM);
    this.tableContent = this.tableDOM.querySelector(options.tableContent);

    /** Pagination */
    this.paginationDOM = this.tableDOM.querySelector(options.pagination);
    this.hidePagination =  options.hidePagination;
    this.paginationData;  // response from api

    this.searchButtonDOM = document.querySelector(options.searchButtonDOM);

    this._injectListWrapperHTML();
    this._injectListHTML();
    this._bindEvents();
    this._makeApiCallTable();
  }

  _bindEvents(){
    this.inputDOM.addEventListener('focus', () => this.toggleList(true));
    this.inputDOM.addEventListener('focusout', () => this.toggleList(false));
    this.inputDOM.addEventListener('keyup', (event) => this.keyUpEvent(event));

    this.searchButtonDOM.addEventListener('click', () => this.searchEvent());
  }

  _injectListWrapperHTML(){
    const listWrapper = document.createElement('div');
    listWrapper.className = 'select-list';
    this.inputGroupDOM.appendChild(listWrapper);
    this.listWrapperDOM = this.inputGroupDOM.querySelector('.select-list');
  }

  _injectListHTML(){
    const listItems = this.list.map(item => {
      return `<div class="select-list__item">${item}</div>`
    });

    this.listWrapperDOM.innerHTML = listItems.join('');
  }

  toggleList(flag){
    this.listShow = flag || !this.listShow;
    if(this.listShow && this.list.length > 0){
      this.listWrapperDOM.classList.add('is-active');
      this._listBindEvents();
    }else{
      setTimeout(() => {
        this.listWrapperDOM.classList.remove('is-active');
      }, 200);
    }
  }

  _listBindEvents(){
    const listItems = this.listWrapperDOM.querySelectorAll('.select-list__item');

    listItems.forEach((item, index) =>
      item.addEventListener('click', () => this.chooseListItem(index))
    );
  }

  _listResolveEvents(){

  }

  chooseListItem(index){
    this.activeListItem = index;

    this.inputDOM.value = this.list[index];
    this.toggleList(false);
  }

  keyUpEvent(event){
    if(this.inputDOM.value.length > 0){
      this._makeApiCall();
      this.toggleList(true);
    }else{
      this.list == [];
      this.toggleList(false);
    }

    if(event.keyCode === 13){
      this.searchEvent();
    }
  }

  _makeApiCall(){
    /** Get queried params like - sort/archive/phrase */
    const sort = getURLParameter('sort');
    let archive = getURLParameter('archive');
    if(archive == null) archive = false;

    /** Create api URL based on current query params and add phrase */
    const query = `${this.fetchApi}?limit=${this.listItemsLimit}&dataComplex=small&multiple=true&type=searchStraight`;
    let apiUrl = updateURLParameter(query, 'phrase', this.inputDOM.value);
    apiUrl = updateURLParameter(apiUrl, 'sort', sort || 'date');
    apiUrl = updateURLParameter(apiUrl, 'archive', archive);

    axios.get(apiUrl)
      .then(response => {
        // if(response.data.success){
          const uniqueResponse = removeDuplicates(response.data.items)
          this.list = uniqueResponse;
          this._injectListHTML();

        // }else{
          // TODO: STATUS MODAL WITH ERROR
        // }
      }).catch(err=>{
        console.log(err);
      })
  }

  searchEvent(){
    if(this.inputDOM.value.length > 0){
      this._makeApiCallTable();
    }
  }

  _makeApiCallTable(page){
    /** Set new url */
    let newURL = updateURLParameter(window.location.href, 'phrase', this.inputDOM.value);
    newURL = updateURLParameter(newURL, 'page', page || 1);
    window.history.pushState({}, null, newURL);

    /** Get queried params like - sort/archive/phrase */
    const sort = getURLParameter('sort');
    let archive = getURLParameter('archive');
    if(archive == null) archive = false;

    /** Create api URL based on current query params and add phrase */
    const query = `${this.fetchApi}?dataComplex=medium&html=1&multiple=true&type=searchComplex`;
    let apiUrl = updateURLParameter(query, 'phrase', this.inputDOM.value);
    apiUrl = updateURLParameter(apiUrl, 'sort', sort || 'date');
    apiUrl = updateURLParameter(apiUrl, 'archive', archive);
    apiUrl = updateURLParameter(apiUrl, 'page', page || 1);

    this.tableDOM.classList.add(this.loadableClass);

    axios.get(apiUrl)
      .then(response => {
        this.tableDOM.classList.remove(this.loadableClass);
        // if(response.data.success){
          this.paginationData = response.data.pagination;
          this.tableContent.innerHTML = response.data.items;
          this.renderPagination();
          // if(this.hidePagination) this.paginationDataDOM.innerHTML = '';
        // }else{
          // TODO: STATUS MODAL
        // }
      }).catch(err=>{
        console.log(err);
      })
  }

  renderPagination() {
    // should render items by this search module
    let buttons = [];
    if(this.paginationData.hasPreviousPage) buttons.push(`<div class="btn btn--red" title="Przejdź do strony ${this.paginationData.previousPage}" data-page='${this.paginationData.previousPage}'>Poprzednia strona</div>`)
    buttons.push(`<div class="btn btn--red h-ml-2 h-mr-2">${this.paginationData.currentPage}</div>`);
    if(this.paginationData.hasNextPage) buttons.push(`<div class="btn btn--red" title="Przejdź do strony ${this.paginationData.nextPage}" data-page='${this.paginationData.nextPage}'>Następna strona</div>`)

    this.paginationDOM.innerHTML = buttons.join('');


    const paginationItems = this.paginationDOM.querySelectorAll('[data-page]');
    paginationItems.forEach(item => item.addEventListener('click', () => this._makeApiCallTable(item.getAttribute('data-page'))))
  }
}

export default Search;